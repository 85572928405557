import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()

export class DataService {

  // public urlDades = 'http://localhost/';
  public urlDades = 'https://consentimientodolz.com/api/';
  public token = sessionStorage.getItem('token');

  constructor(private _http: HttpClient) { }

  login(username, password) {
    const params = '{"user":"' + username + '","password":"' + password + '"}';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post(this.urlDades + 'checkuserlogin.php', params, { headers: headers }).pipe(map((response: any) => response));
  }

  enviarDatos(nombre, dni, dia_tratamiento, poblacion, telefono, email, fecha, firma, lote, color, aplicador, centro, notas) {
    var stillUtc1 = moment.utc(dia_tratamiento).toDate();
    var dia = moment(stillUtc1).local().format('YYYY-MM-DD');

    const params = '{"nombre":"' + nombre +
    '","token":"' + this.token +
    '","dni":"' + dni +
    '","dia_tratamiento":"' + dia +
    '","poblacion":"' + poblacion +
    '","telefono":"' + telefono +
    '","email":"' + email +
    '","fecha":"' + fecha +
    '","firma":"' + firma +
    '","lote":"' + lote +
    '","color":"' + color +
    '","centro":"' + centro +
    '","notas":"' + notas +
    '", "aplicador":"' + aplicador + '"}';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post(this.urlDades + 'enviardatos.php', params, { headers: headers }).pipe(map((response: any) => response));
  }

  enviarDatosLaser(nombre, dni, dia_tratamiento, poblacion, telefono, email, fecha, firma, centro, notas) {
    var stillUtc1 = moment.utc(dia_tratamiento).toDate();
    var dia = moment(stillUtc1).local().format('YYYY-MM-DD');

    const params = '{"nombre":"' + nombre +
    '","token":"' + this.token +
    '","dni":"' + dni +
    '","dia_tratamiento":"' + dia +
    '","poblacion":"' + poblacion +
    '","telefono":"' + telefono +
    '","email":"' + email +
    '","fecha":"' + fecha +
    '","firma":"' + firma +
    '","centro":"' + centro +
    '","notas":"' + notas + '"}';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post(this.urlDades + 'enviardatoslaser.php', params, { headers: headers }).pipe(map((response: any) => response));
  }

  getConsentimientos(){
    const params = '{"token":"' + this.token + '"}';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post(this.urlDades + 'getconsentimientos.php', params, { headers: headers }).pipe(map((response: any) => response));
  }

  getConsentimientosLaser(){
    const params = '{"token":"' + this.token + '"}';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post(this.urlDades + 'getconsentimientoslaser.php', params, { headers: headers }).pipe(map((response: any) => response));
  }

  actualizarDatos(firma, idconsentimiento, nombre, dni, poblacion, email, telefono, lote, color, aplicador, centro, notas){
    const params = '{"token":"' + this.token +
    '", "firma":"' + firma +
    '", "nombre":"' + nombre +
    '", "dni":"' + dni +
    '", "poblacion":"' + poblacion +
    '", "email":"' + email +
    '", "telefono":"' + telefono +
    '", "lote":"' + lote +
    '", "color":"' + color +
    '","centro":"' + centro +
    '","notas":"' + notas +
    '", "aplicador":"' + aplicador +
    '", "idconsentimiento":"' + idconsentimiento + '"}';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post(this.urlDades + 'actualizardatos.php', params, { headers: headers }).pipe(map((response: any) => response));
  }

  actualizarDatosLaser(firma, idconsentimiento, nombre, dni, poblacion, email, telefono, centro, notas){
    const params = '{"token":"' + this.token +
    '", "firma":"' + firma +
    '", "nombre":"' + nombre +
    '", "dni":"' + dni +
    '", "poblacion":"' + poblacion +
    '", "email":"' + email +
    '", "telefono":"' + telefono +
    '","centro":"' + centro +
    '","notas":"' + notas +
    '", "idconsentimiento":"' + idconsentimiento + '"}';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post(this.urlDades + 'actualizardatoslaser.php', params, { headers: headers }).pipe(map((response: any) => response));
  }

  removeConsentimiento(idconsentimiento){
    const params = '{"token":"' + this.token + '", "idconsentimiento":"' + idconsentimiento + '"}';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post(this.urlDades + 'eliminarconsentimiento.php', params, { headers: headers }).pipe(map((response: any) => response));
  }

  removeConsentimientoLaser(idconsentimiento){
    const params = '{"token":"' + this.token + '", "idconsentimiento":"' + idconsentimiento + '"}';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post(this.urlDades + 'eliminarconsentimientolaser.php', params, { headers: headers }).pipe(map((response: any) => response));
  }
  
}