import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cometesunfix'
})
export class CometesunfixPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let modificada = value.replace(/&#34/g, "\"");
    modificada = modificada.replace(/&#39/g, "\'");

    return modificada;
  }

}
