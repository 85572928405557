import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsentimientoComponent } from './consentimiento/consentimiento.component';
import { ConsentimientolaserComponent } from './consentimientolaser/consentimientolaser.component';
import { LoginComponent } from './login/login.component';
import { PacientesComponent } from './pacientes/pacientes.component';
import { PacienteslaserComponent } from './pacienteslaser/pacienteslaser.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'iniciosesion', component: LoginComponent },
  { path: 'consentimiento', component: ConsentimientoComponent },
  { path: 'pacientes', component: PacientesComponent },
  { path: 'consentimientolaser', component: ConsentimientolaserComponent },
  { path: 'pacienteslaser', component: PacienteslaserComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }