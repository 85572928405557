import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brfix'
})
export class BrfixPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    let modificada = value.replace(/\n/g, "<br />");
    modificada = modificada.replace(/\r/g, "<br />");

    return modificada;
  }

}
