import { Component, OnInit, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import * as moment from 'moment';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { BrfixPipe } from '../pipes/brfix.pipe';
import { CometesfixPipe } from '../pipes/cometesfix.pipe';
import { DataService } from '../services/dataservices';

@Component({
  selector: 'app-consentimientolaser',
  templateUrl: './consentimientolaser.component.html',
  styleUrls: ['./consentimientolaser.component.css'],
  providers: [DataService, MessageService, BrfixPipe, CometesfixPipe]
})
export class ConsentimientolaserComponent implements OnInit {

  public user;
  public token;
  public logged;
  public msgs1: Message[];

  public nombre;
  public dni;
  public dia_tratamiento = moment(new Date()).format("DD/MM/YYYY");
  public poblacion;
  public telefono;
  public email;
  public fecha;
  public firma;
  public notas;
  public centro;

  items: MenuItem[];

  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  public signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 250,
    'canvasHeight': 150,
    backgroundColor: 'rgb(255,255,255)',
  };

  constructor(private dataservice: DataService, private messageService: MessageService,
    private brFix: BrfixPipe, private cometesFix: CometesfixPipe) { }

  ngOnInit(): void {
    this.user = sessionStorage.getItem("user");
    this.token = sessionStorage.getItem("token");
    if (this.user != null && this.token != null) {
      this.logged = true;
    } else {
      this.logged = false;
    }
    this.msgs1 = [
      { severity: 'error', summary: 'Error', detail: 'Inicia sesión para poder acceder al contenido' }
    ];
    this.items = [
      {
        label: 'Consentimiento',
        icon: 'pi pi-file',
        command: (event) => { this.goToConsentiment(); }
      },
      {
        label: 'Consentimiento Láser',
        icon: 'pi pi-copy',
        command: (event) => { this.goToConsentimentLaser(); }
      },
      {
        label: 'Listado Consentimientos',
        icon: 'pi pi-users',
        command: (event) => { this.goToPacients(); }
      },
      {
        label: 'Listado Consentimiento Láser',
        icon: 'pi pi-users',
        command: (event) => { this.goToPacientsLaser(); }
      }];
  }

  drawComplete() {
    if (this.signaturePad.toDataURL() != null) {
      this.firma = this.signaturePad.toDataURL();
    }
    else {
      this.firma == null;
    }
  }

  drawClear() {
    this.signaturePad.clear();
  }

  desconect() {
    this.user = sessionStorage.removeItem("user");
    this.token = sessionStorage.removeItem("token");
    window.location.href = '';
  }

  enviarDatos() {
    var dat = new Date();
    this.firma = this.signaturePad.toDataURL();
    if (this.notas == null){
      this.notas = '';
    }
    this.dataservice.enviarDatosLaser(this.nombre, this.dni, dat, this.poblacion, this.telefono, this.email, this.fecha, this.firma, this.centro, this.brFix.transform(this.cometesFix.transform(this.notas))).subscribe(
      response => {
        if (response == 'Ok') {
          this.nombre = null;
          this.dni = null;
          this.dia_tratamiento = moment(new Date()).format("DD/MM/YYYY");
          this.poblacion = null;
          this.telefono = null;
          this.email = null;
          this.fecha = null
          this.firma = null;
          this.notas = null;
          this.centro = null;
          this.showOk();
          this.signaturePad.clear();
        } else if (response == 'Error') {
          this.showError();
        }
      }, error => {
        console.log('Error en la petición:' + error);
      });
  }

  showOk() {
    this.messageService.add({ key: 'bc', severity: 'success', summary: 'OK', detail: 'Datos enviados correctamente' });
  }

  showError() {
    this.messageService.add({ key: 'bc', severity: 'error', summary: 'Error', detail: 'Los datos no se han enviado. Algun dato estaba incorrecto' });
  }

  goToConsentiment() {
    window.location.href = 'consentimiento';
  }

  goToConsentimentLaser(){
    window.location.href = 'consentimientolaser';
  }

  goToPacients() {
    window.location.href = 'pacientes';
  }

  goToPacientsLaser() {
    window.location.href = 'pacienteslaser';
  }

}
