import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brunfix'
})
export class BrunfixPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let modificada = value.replace(/\<br \/>/g, "\n");
    modificada = modificada.replace(/\<br\/>/g, "\n");

    return modificada;
  }
  
}
