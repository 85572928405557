import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { BrfixPipe } from '../pipes/brfix.pipe';
import { BrunfixPipe } from '../pipes/brunfix.pipe';
import { CometesfixPipe } from '../pipes/cometesfix.pipe';
import { CometesunfixPipe } from '../pipes/cometesunfix.pipe';
import { DataService } from '../services/dataservices';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pacientes',
  templateUrl: './pacientes.component.html',
  styleUrls: ['./pacientes.component.css'],
  providers: [DataService, MessageService, BrfixPipe, CometesfixPipe, BrunfixPipe, CometesunfixPipe, DatePipe]
})
export class PacientesComponent implements OnInit {

  public user;
  public token;
  public logged;
  public msgs1: Message[];

  public activatedEdition;
  public consentimientosList;
  public selectedConsentimiento;
  public columns;

  public idconsentimiento;
  items: MenuItem[];

  public nombre;
  public dni;
  public dia_tratamiento;
  public poblacion;
  public telefono;
  public email;
  public fecha: Date;
  public firma;

  public lote;
  public color;
  public aplicador;
  public notas;
  public centro;

  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  public signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 250,
    'canvasHeight': 150,
    backgroundColor: 'rgb(255,255,255)',
  };

  constructor(private dataservice: DataService, private messageService: MessageService,
    private brFix: BrfixPipe, private cometesFix: CometesfixPipe, private brUnfix: BrunfixPipe,
    private cometesUnfix: CometesunfixPipe, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.user = sessionStorage.getItem("user");
    this.token = sessionStorage.getItem("token");
    this.activatedEdition = false;
    if (this.user != null && this.token != null) {
      this.logged = true;
      this.getConsentimientos();
    } else {
      this.logged = false;
    }
    this.msgs1 = [
      { severity: 'error', summary: 'Error', detail: 'Inicia sesión para poder acceder al contenido' }
    ];
    this.items = [
      {
        label: 'Consentimiento',
        icon: 'pi pi-file',
        command: (event) => { this.goToConsentiment(); }
      },
      {
        label: 'Consentimiento Láser',
        icon: 'pi pi-copy',
        command: (event) => { this.goToConsentimentLaser(); }
      },
      {
        label: 'Listado Consentimientos',
        icon: 'pi pi-users',
        command: (event) => { this.goToPacients(); }
      },
      {
        label: 'Listado Consentimiento Láser',
        icon: 'pi pi-users',
        command: (event) => { this.goToPacientsLaser(); }
      }];
    this.columns = [
      { field: 'id', header: 'ID', mida: '0.5%' },
      { field: 'nombre', header: 'NOMBRE', mida: '2%' },
      { field: 'acciones', header: 'ACCIONES', mida: '2%' },
    ];
  }

  getConsentimientos() {
    this.consentimientosList = null;
    this.dataservice.getConsentimientos().subscribe(
      response => {
        this.consentimientosList = response;
      }, error => {
        console.log('Error en la petición:' + error);
      });
    this.selectedConsentimiento = null;
  }

  goToConsentiment() {
    window.location.href = 'consentimiento';
  }

  goToPacients() {
    window.location.href = 'pacientes';
  }

  goToPacientsLaser() {
    window.location.href = 'pacienteslaser';
  }

  goToConsentimentLaser(){
    window.location.href = 'consentimientolaser';
  }

  desconect() {
    this.user = sessionStorage.removeItem("user");
    this.token = sessionStorage.removeItem("token");
    window.location.href = '';
  }

  addInfo(consentimiento){
    this.activatedEdition = true;
    this.idconsentimiento = consentimiento.id;
    this.firma = consentimiento.firma;
    this.nombre = consentimiento.nombre;
    this.dia_tratamiento = this.datePipe.transform(consentimiento.dia_tratamiento,"dd/MM/yyyy")
    if (consentimiento.nombre == 'undefined'){
      this.nombre = '';
    }
    this.dni = consentimiento.dni;
    if (consentimiento.dni == 'undefined'){
      this.dni = '';
    }
    this.poblacion = consentimiento.poblacion;
    if (consentimiento.poblacion == 'undefined'){
      this.poblacion = '';
    }
    this.email = consentimiento.email;
    if (consentimiento.email == 'undefined'){
      this.email = '';
    }
    this.telefono = consentimiento.telefono;
    if (consentimiento.telefono == 'undefined'){
      this.telefono = '';
    }
    this.lote = consentimiento.lote;
    if (consentimiento.lote == 'undefined'){
      this.lote = '';
    }
    this.color = consentimiento.color;
    if (consentimiento.color == 'undefined'){
      this.color = '';
    }
    this.aplicador = consentimiento.aplicador;
    if (consentimiento.aplicador == 'undefined'){
      this.aplicador = '';
    }
    this.notas = this.brUnfix.transform(this.cometesUnfix.transform(consentimiento.notas));
    if (consentimiento.notas == 'undefined'){
      this.notas = '';
    }
    this.centro = consentimiento.centro;
    if (consentimiento.centro == 'undefined'){
      this.centro = '';
    }
  }

  enviarDatos(){
    if (this.signaturePad.isEmpty() == false){
      this.firma = this.signaturePad.toDataURL();
    }
    this.dataservice.actualizarDatos(this.firma, this.idconsentimiento, this.nombre, this.dni, this.poblacion, this.email, this.telefono, this.lote, this.color, this.aplicador, this.centro, this.brFix.transform(this.cometesFix.transform(this.notas))).subscribe(
      response => {
        if (response == 'Ok') {
          window.location.href = 'pacientes';
          this.showOk();
          this.signaturePad.clear();
        } 
        else if(response == 'Error'){
          this.showError();
        }
      }, error => {
        console.log('Error en la petición:' + error);
      });
  }

  removeConsentimiento(idconsentimiento){
    const aprobacio = window.confirm('Seguro que quieres eliminar el consentimiento con la ID: ' + idconsentimiento + '?');
    if (!aprobacio) {
      return false;
    }
    else{
      this.dataservice.removeConsentimiento(idconsentimiento).subscribe(
        response => {
          if (response == 'Ok') {
            this.showOk();
            this.getConsentimientos();
          } 
          else if(response == 'Error'){
            this.showError();
          }
        }, error => {
          console.log('Error en la petición:' + error);
        });
    }
  }

  drawClear() {
    this.signaturePad.clear();
  }

  showOk() {
    this.messageService.add({ key: 'bc', severity: 'success', summary: 'OK', detail: 'Datos actualizados correctamente' });
  }

  showError() {
    this.messageService.add({ key: 'bc', severity: 'error', summary: 'Error', detail: 'Los datos no se han actualizado. Algun dato estaba incorrecto' });
  }

  back(){
    this.activatedEdition = false;
    this.getConsentimientos();
  }


}
