<ng-container *ngIf="logged == true">
    <p-toast position="bottom-center" key="bc"></p-toast>
    <div class="p-row" *ngIf="activatedEdition == false">
        <div class="center">
            <p-menubar [model]="items">
                <ng-template pTemplate="end">
                    <button pButton pRipple type="button" label="Desconectar" (click)="desconect()" class="p-button-primary"></button>
                </ng-template>
            </p-menubar>
        
            <div class="card">
                <h4 style="text-align: center;"><b>LISTADO CONSENTIMIENTOS</b></h4>
                <p-table #dt [value]="consentimientosList" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                    currentPageReportTemplate="Mostrando entrada {first} de {totalRecords}"
                    [globalFilterFields]="['id','nombre']" [filterDelay]="0" sortField="tractament" [sortOrder]="1">
        
                    <ng-template pTemplate="caption">
                        <div class="table-header" style="text-align: left;">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Búsqueda Global" />
                            </span>
                        </div>
                    </ng-template>
        
                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn
                                [style.width]="col.mida">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-consentimiento>
                        <tr>
                            <td scope="row">{{consentimiento.id}}</td>
                            <td style="text-align: justify" (click)="addInfo(consentimiento);"><b class="cursor" style="color: cadetblue;">{{consentimiento.nombre}}</b></td>
                            <td scope="row">
                                <button pButton pRipple type="button" label=" " (click)="addInfo(consentimiento)" class="p-button-help"><i class="pi pi-user-edit"></i></button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button pButton pRipple type="button" label=" " (click)="removeConsentimiento(consentimiento.id)" class="p-button-danger"><i class="pi pi-trash"></i></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

        </div>
    </div>
    <div class="p-row" *ngIf="activatedEdition == true">
        <div class="center">
            <p-menubar [model]="items">
                <ng-template pTemplate="end">
                    <button pButton pRipple type="button" label="Desconectar" (click)="desconect()" class="p-button-primary"></button>
                </ng-template>
            </p-menubar>
            <div class="p-d-flex p-flex-column" style="text-align: center;">
                <div class="p-mb-2">
                    <h3>DATOS DE <b style="color: rgb(52, 120, 122);">{{nombre}}</b></h3>
                    <label><b>DNI</b></label>
                    <br />
                    <input type="text" pInputText [(ngModel)]="dni"> 
                    <br /><br />
                    <label><b>POBLACIÓN</b></label>
                    <br />
                    <input type="text" pInputText [(ngModel)]="poblacion"> 
                    <br /><br />
                    <label><b>EMAIL</b></label>
                    <br />
                    <input type="text" pInputText [(ngModel)]="email"> 
                    <br /><br />
                    <label><b>TELÉFONO</b></label>
                    <br />
                    <input type="text" pInputText [(ngModel)]="telefono"> 
                    <br /><br />
                    <label><b>CENTRO</b></label>
                    <br />
                    <input type="text" pInputText [(ngModel)]="centro">
                    <br /><br />
                    <label><b>LOTE</b></label>
                    <br />
                    <input type="text" pInputText [(ngModel)]="lote"> 
                    <br /><br />
                    <label><b>COLOR</b></label>
                    <br />
                    <input type="text" pInputText [(ngModel)]="color">
                    <br /><br />
                    <label><b>APLICADOR</b></label>
                    <br />
                    <input type="text" pInputText [(ngModel)]="aplicador">
                    <br /><br />
                    <label><b>1er DIA TRATAMIENTO</b></label>
                    <br />
                    <input type="text" pInputText [(ngModel)]="dia_tratamiento" readonly>
                    <br /><br />
                    <label><b>NOTAS</b></label>
                    <br />
                    <textarea pInputTextarea [rows]="5" [cols]="30" [(ngModel)]="notas" autoResize="autoResize"></textarea>
                    <br /><br />
                    <label><b>FIRMA ACTUAL</b></label>
                    <br />
                    <img style='display:block; width:100px;height:100px;' id='base64image' [src]="firma"/>
                    <br /><br />
                    <h5>MODIFICAR FIRMA</h5>
                    <signature-pad [options]="signaturePadOptions"></signature-pad>
                    <br />
                    <button pButton pRipple type="button" (click)="drawClear()" label="ELIMINAR FIRMA" class="p-button-danger"></button>
                </div>
                <div class="p-mb-2" style="text-align: center;">
                    <br /><br /><br /><br />
                    <button pButton pRipple type="button" (click)="back()" label=" " class="p-button-info"><i class="pi pi-arrow-left"></i></button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button pButton pRipple type="button" (click)="enviarDatos()" label="ACTUALIZAR" class="p-button-success"></button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="logged == false">
    <div class="center">
        <p-messages [(value)]="msgs1" [enableService]="false"></p-messages>
    </div>
</ng-container>