<div id="login-page" class="row center">
    <div>
        <p-card>
            <form name="form">
                <div class="form-group" style="text-align: center;">
                    <h1 class="container">CONSENTIMIENTO DOLZ</h1>
                </div>
                <div class="form-group" style="text-align: center;">
                    <label for="username"><b>USUARIO</b></label>
                    <br />
                    <input type="text" name="usuari" [(ngModel)]="usuari" (keydown)=onKeydownEvent($event) pInputText>
                </div>
                <br />
                <div class="form-group" style="text-align: center;">
                    <label for="password"><b>CONTRASEÑA</b></label>
                    <br />
                    <input type="password" name="contrasenya" [(ngModel)]="contrasenya" (keydown)=onKeydownEvent($event) pInputText>
                </div>
                <div class="form-group" style="text-align: center;" *ngIf="logged == false">
                    <p-messages [(value)]="msgs1" [enableService]="false"></p-messages>
                </div>
                <br />
                <div class="form-group" style="text-align: center;">
                    <p-button label="INICIAR SESIÓN" (click)="logIn()"></p-button>
                </div>
            </form>
        </p-card>
    </div>
</div>