import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { ConsentimientoComponent } from './consentimiento/consentimiento.component';

import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import {MenuItem} from 'primeng/api';                  //api

import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {MenubarModule} from 'primeng/menubar';
import {TableModule} from 'primeng/table';
import {InputTextareaModule} from 'primeng/inputtextarea';

import { SignaturePadModule } from 'angular2-signaturepad';
import { PacientesComponent } from './pacientes/pacientes.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrfixPipe } from './pipes/brfix.pipe';
import { CometesfixPipe } from './pipes/cometesfix.pipe';
import { BrunfixPipe } from './pipes/brunfix.pipe';
import { CometesunfixPipe } from './pipes/cometesunfix.pipe';
import { ConsentimientolaserComponent } from './consentimientolaser/consentimientolaser.component';
import { PacienteslaserComponent } from './pacienteslaser/pacienteslaser.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConsentimientoComponent,
    PacientesComponent,
    BrfixPipe,
    CometesfixPipe,
    BrunfixPipe,
    CometesunfixPipe,
    ConsentimientolaserComponent,
    PacienteslaserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InputTextModule,
    ButtonModule,
    CardModule,
    FormsModule,
    MessagesModule,
    MessageModule,
    SignaturePadModule,
    ToastModule,
    MenubarModule,
    TableModule,
    InputTextareaModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
