import { Component, OnInit } from '@angular/core';
import { Message } from 'primeng/api';
import { DataService } from 'src/app/services/dataservices';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [DataService]
})
export class LoginComponent implements OnInit {

  public usuari;
  public contrasenya;

  public logged;
  public msgs1: Message[];
;

  constructor(private dataservice: DataService) { }

  ngOnInit(): void {
    this.msgs1 = [{severity:'error', summary:'Error', detail:'Usuario o contaseña incorrectos'}];
  }

  logIn() {
    if (this.usuari != null && this.contrasenya != null) {
      this.dataservice.login(this.usuari, this.contrasenya).subscribe(
        response => {
          if (response && response.length > 0) {
            this.logged = true;
            sessionStorage.setItem('user', this.usuari);
            sessionStorage.setItem('token', response[1]);
            window.location.href = 'consentimiento';
          }
          else {
            this.logged = false;
          }
        }, error => {
          console.log('Error en la petición:' + error);
        });
    }
  }

  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      if (this.usuari != null && this.contrasenya != null) {
        this.dataservice.login(this.usuari, this.contrasenya).subscribe(
          response => {
            if (response && response.length > 0) {
              this.logged = true;
              sessionStorage.setItem('user', this.usuari);
              sessionStorage.setItem('token', response[1]);
              window.location.href = 'consentimiento';
            }
            else {
              this.logged = false;
            }
          }, error => {
            console.log('Error en la petición:' + error);
          });
      }
    }
  }

}
