<ng-container *ngIf="logged == true">
    <p-toast position="bottom-center" key="bc"></p-toast>
    <div class="p-row">
        <div class="center">
            <p-menubar [model]="items">
                <ng-template pTemplate="end">
                    <button pButton pRipple type="button" label="Desconectar" (click)="desconect()"
                        class="p-button-primary"></button>
                </ng-template>
            </p-menubar>
            <div class="p-d-flex p-flex-column">
                <div class="p-mb-2">
                    <h1 style="text-align: center;">CONSENTIMIENTO</h1>
                    <p style="text-align: justify;">
                        Yo, <input class="p-inputtext-sm" placeholder="NOMBRE Y APELLIDOS" type="text" name="nombre"
                            [(ngModel)]="nombre" pInputText>, (de aquí en adelante “paciente”) mayor de edad, con
                        DNI <input class="p-inputtext-sm" placeholder="DNI" type="text" name="dni" [(ngModel)]="dni"
                            pInputText> como cliente en pleno uso de mis facultades, libre y voluntariamente, autorizo
                        al
                        técnico especialista hoy día <b>{{dia_tratamiento}}</b> para la realización de una sesión del
                        tratamiento de MICROBLADING DE
                        CEJAS.
                    </p>
                </div>
            </div>

            <h3 style="text-align: center;">Datos del paciente</h3>
            <div style="text-align: center;">
                <label>POBLACIÓN</label>
                <br />
                <input class="p-inputtext-sm" placeholder="POBLACIÓN" type="text" name="poblacion"
                    [(ngModel)]="poblacion" pInputText>
                <br />
                <label>TELÉFONO</label>
                <br />
                <input class="p-inputtext-sm" placeholder="TELÉFONO" type="text" name="telefono" [(ngModel)]="telefono"
                    pInputText>
                <br />
                <label>EMAIL</label>
                <br />
                <input class="p-inputtext-sm" placeholder="EMAIL" type="email" name="email" [(ngModel)]="email"
                    pInputText>
                <br />
                <label>FECHA NACIMIENTO</label>
                <br />
                <input class="p-inputtext-sm" placeholder="FECHA NACIMIENTO" type="date" name="fecha"
                    [(ngModel)]="fecha" pInputText>
                <br />
                <label>CENTRO</label>
                <br />
                <input class="p-inputtext-sm" placeholder="CENTRO" type="text" name="centro"
                    [(ngModel)]="centro" pInputText>
                <br />
                <br />
                <label>FIRMA</label>
                <br />
                <signature-pad [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad>
                <br />
                <button pButton pRipple type="button" (click)="drawClear()" label="ELIMINAR FIRMA"
                    class="p-button-danger"></button>
                <br />
                <br />
                <label>NOTAS</label>
                <br />
                <textarea pInputTextarea [rows]="5" [cols]="30" [(ngModel)]="notas" autoResize="autoResize"></textarea>
            </div>
            <h3 style="text-align: center;">INFORMACION GENERAL DEL TRATAMIENTO</h3>
            <p style="text-align: justify;">
                Se le informa al paciente que el microblading es una técnica de microimplantación de pigmentos en la
                epidermis de la piel, cuyos resultados se irán desvaneciendo gradualmente de forma natural.
                El pigmento implantado será más oscuro durante los primeros días, debido al proceso de oxidación, en lo
                sucesivo por el recambio celular y la eliminación linfática, el pigmento va a perder del 30% al 50% de
                intensidad. El resultado del tratamiento con el color estabilizado de evaluarse después de 30 días.
                Aunque haya sido pensado como color no permanente, el proceso de eliminación está influenciado por el
                metabolismo de cada persona, luego no hay ninguna garantía de que el pigmento se elimine completamente.
                En pieles muy finas o que sangren mucho con el tratamiento el resultado puede no ser el óptimo o puede
                ser
                expulsado completamente.
                Los pigmentos utilizados son hipoalergénicos, no irritantes, no tóxicos, no mutágenos no cancerígenos,
                no
                inmunotóxicos ni corrosivos.
                El primer retoque está incluido en el precio de la tarifa vigente en la fecha del tratamiento.
                Como mantenimiento, se recomienda hacer una sesión de retoque anualmente. El coste del mantenimiento
                será
                según la tarifa vigente.
            </p>
            <h3 style="text-align: center;">INFORMACIÓN ESPECÍFICA DEL TRATAMIENTO</h3>
            <p style="text-align: justify;">
                El técnico especialista me ha realizado una prueba de visagismo (diseño) que he comprobado, teniendo en
                cuenta las características morfológicas y personales, siendo la forma y la posición de mi agrado.
            </p>
            <h3 style="text-align: center;">INDICACIONES PREVIAS AL TRATAMIENTO</h3>
            <p style="text-align: justify;">
                Es responsabilidad del paciente a seguir las siguientes indicaciones previas al tratamiento. Si el
                paciente
                no actúa de la forma adecuada, Sandra Dolz se exime de toda responsabilidad e inconvenientes que
                pudieran
                surgir. No seguir las indicaciones puede alterar el resultado final del tratamiento debido al exceso de
                sangrado que provoca el mismo.
            </p>
            24 horas previas al tratamiento:
            <ul>-NO ingerir bebidas excitantes; té, café, refrescos con cafeína.</ul>
            <ul>-NO tomar medicamentos vasodilatadores o anticoagulantes.</ul>
            <ul>-No ingerir alcohol, ácido acetilsalicílico (aspirinas, ibuprofeno, paracetamol)</ul>
            <ul>-NO tomar comidas picantes, fuertes o marisco</ul>
            <ul>-NO realizarse tratamientos faciales.</ul>
            <ul>-Evitar SOL y Rayos UVA (72 horas antes)</ul>
            48 horas previas al tratamiento:
            <ul>-NO tomar vitamina E</ul>
            <ul>-NO tomar antidepresivos</ul>
            <h3 style="text-align: center;">CUIDADOS POSTERIORES AL TRATAMIENTO</h3>
            <p style="text-align: justify;">
                Es responsabilidad del paciente realizar correctamente los cuidados posteriores al tratamiento. Si el
                paciente no actúa de la forma adecuada, Sandra Dolz se exime de toda responsabilidad e inconvenientes
                que
                pudiera surgir.
                <br />
                <br />
                <b>Primer día:</b>
                <br />
                <br />
                Lavar la zona con agua y jabón neutro cada media hora. Secar suavemente y aplicar crema específica.
                <br />
                <br />
                <b>Segundo día:</b>
                <br />
                <br />
                Piel normal: Sólo crema específica 3 veces al día durante 7 días.
                Piel grasa: Cicatrización en seco. Evitar todo tipo de cremas y cosméticos durante 7 días.
                <br />
                <br />
                <b>10-15 días</b> sin exposición al sol.
                <br />
                <br />
                <b>10-15 días</b> evitar piscinas, jacuzzi, sauna, agua del mar.
                <br />
                <br />
                Cuando ya haya cicatrizado aplicar protector solar habitualmente.
                Si en el proceso de post-tratamiento apareciese cualquier reacción o alteración, consulte a su médico.
            </p>
            <h3 style="text-align: center;">MANTENIMIENTO</h3>
            <p style="text-align: justify;">
                Siempre que se lleve un tratamiento de microblading se aconseja utilizar protector solar, proteger del
                contacto con ácidos glicólicos, retinol y de los láser depilatorios.
            </p>
            <h3 style="text-align: center;">REVERSIBILIDAD</h3>
            <p style="text-align: justify;">
                El tratamiento de Microblading es reversible mediante su eliminación por láser, dicha reversibilidad es
                de
                carácter costoso y puede ser doloroso.
            </p>
            <h3 style="text-align: center;">CONTRAINDICACIONES</h3>
            <p style="text-align: justify;">
                <b>TEMPORALES:</b> Menstruación, herpes simplex o zoster, conjuntivitis, debilidad inmunológica,
                embarazo,
                lactancia, quimioterapia o radioterapia, infección local, cicatrices no estabilizadas, afecciones de la
                piel
                en la zona de la aplicación ( dermatitis local, hematoma, quemaduras solares, ulceras de piel) e
                infecciones
                bacterianas, fúngicas o víricas.
                <br /><br />
                <b>TOTALES:</b> Reacciones alérgicas a los pigmentos, afecciones de la piel en la zona de la aplicación
                ( pecas,
                lunares, queloides, verrugas melanomas, psoriasis, urticaria, cloasma y cáncer de piel).
                <br /><br />
                <b>BAJO SUPERVISIÓN MÉDICA:</b> Diabetes, hemofilia, cardiopatías, VIH, alteraciones de la piel o
                lesiones cutáneas
                no diagnosticadas en la zona de aplicación y otras patologías crónicas.
                <br /><br />
                Se me informa de las contraindicaciones y riesgos que con lleva para la salud si esta técnica fuera mal
                aplicada, y confirmo que soy apta para el tratamiento, asumiendo como riesgos propios, no seguir las
                indicaciones que se me informan. He recibido las recomendaciones e instrucciones a seguir tanto antes,
                durante como después del tratamiento y me comprometo a seguirlas sin necesidad de control alguno por
                parte
                del técnico especialista.
                <br /><br />
                He podido aclarar todas mis dudas acerca de lo anteriormente expuesto y para que así conste la
                conformidad,
                doy firma al presente DOCUMENTO DE CONSENTIMIENTO INFORMADO.
            </p>
            <h3 style="text-align: center;">CONSENTIMIENTO PARA LA TOMA DE IMÁGENES Y AUTORIZACIÓN PARA SU USO</h3>
            <p style="text-align: justify;">
                Por la presente doy mi consentimiento para que se me tomen fotografías y autorizo su uso para la
                reproducción y difusión en cualquier medio de comunicación por parte de Sandra Dolz Miravete y del
                centro en
                el que estoy.
            </p>
            <h3 style="text-align: center;">Pigmentos</h3>
            <div style="text-align: center;">
                <label>NÚMERO DE LOTE</label>
                <br />
                <input class="p-inputtext-sm" placeholder="Nº LOTE" type="number" name="lote" [(ngModel)]="lote"
                    pInputText>
                <br />
                <br />
                <label>COLOR</label>
                <br />
                <input class="p-inputtext-sm" placeholder="COLOR" type="text" name="color" [(ngModel)]="color"
                    pInputText>
                <br />
                <br />
                <label>APLICADOR</label>
                <br />
                <input class="p-inputtext-sm" placeholder="APLICADOR" type="text" name="aplicador"
                    [(ngModel)]="aplicador" pInputText>
                <br />
            </div>
            <br />
            <div style="text-align: right;">
                <button pButton pRipple type="button" (click)="enviarDatos();" label="ENVIAR"
                    class="p-button-success"></button>
            </div>
            <br />
        </div>
    </div>
</ng-container>

<ng-container *ngIf="logged == false">
    <div class="center">
        <p-messages [(value)]="msgs1" [enableService]="false"></p-messages>
    </div>
</ng-container>