import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cometesfix'
})
export class CometesfixPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let modificada = value.replace(/\"/g, "&#34");
    modificada = modificada.replace(/\'/g, "&#39");

    return modificada;
  }
  
}
