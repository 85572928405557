<ng-container *ngIf="logged == true">
    <p-toast position="bottom-center" key="bc"></p-toast>
    <div class="p-row">
        <div class="center">
            <p-menubar [model]="items">
                <ng-template pTemplate="end">
                    <button pButton pRipple type="button" label="Desconectar" (click)="desconect()"
                        class="p-button-primary"></button>
                </ng-template>
            </p-menubar>
            <div class="p-d-flex p-flex-column">
                <div class="p-mb-2">
                    <h1 style="text-align: center;">CONSENTIMIENTO LÁSER</h1>
                    <p style="text-align: justify;">
                        Yo, <input class="p-inputtext-sm" placeholder="NOMBRE Y APELLIDOS" type="text" name="nombre"
                            [(ngModel)]="nombre" pInputText>, (de aquí en adelante “paciente”) mayor de edad, con
                        DNI <input class="p-inputtext-sm" placeholder="DNI" type="text" name="dni" [(ngModel)]="dni"
                            pInputText> como cliente en pleno uso de mis facultades, libre y voluntariamente, autorizo
                        al
                        técnico especialista hoy día <b>{{dia_tratamiento}}</b> para la realización de una sesión del
                        tratamiento de LÁSER.
                    </p>
                </div>
            </div>

            <h3 style="text-align: center;">Datos del paciente</h3>
            <div style="text-align: center;">
                <label>POBLACIÓN</label>
                <br />
                <input class="p-inputtext-sm" placeholder="POBLACIÓN" type="text" name="poblacion"
                    [(ngModel)]="poblacion" pInputText>
                <br />
                <label>TELÉFONO</label>
                <br />
                <input class="p-inputtext-sm" placeholder="TELÉFONO" type="text" name="telefono" [(ngModel)]="telefono"
                    pInputText>
                <br />
                <label>EMAIL</label>
                <br />
                <input class="p-inputtext-sm" placeholder="EMAIL" type="email" name="email" [(ngModel)]="email"
                    pInputText>
                <br />
                <label>FECHA NACIMIENTO</label>
                <br />
                <input class="p-inputtext-sm" placeholder="FECHA NACIMIENTO" type="date" name="fecha"
                    [(ngModel)]="fecha" pInputText>
                <br />
                <label>CENTRO</label>
                <br />
                <input class="p-inputtext-sm" placeholder="CENTRO" type="text" name="centro"
                    [(ngModel)]="centro" pInputText>
                <br />
                <br />
                <label>FIRMA</label>
                <br />
                <signature-pad [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad>
                <br />
                <button pButton pRipple type="button" (click)="drawClear()" label="ELIMINAR FIRMA"
                    class="p-button-danger"></button>
                <br />
                <br />
                <label>NOTAS</label>
                <br />
                <textarea pInputTextarea [rows]="5" [cols]="30" [(ngModel)]="notas" autoResize="autoResize"></textarea>
            </div>
            <h3 style="text-align: center;">INFORMACION GENERAL DEL TRATAMIENTO</h3>
            <p style="text-align: justify;">
                Los mejores resultados se obtienen con lentigos solares, tatuajes con tintas oscuras, mientras que los cloasmas, melasmas y tatuajes de colores más claros son más resistentes y requieren de un número mayor de sesiones. <br/><br/>
                Las sesiones de eliminación las repetiremos cada 4 u 8 semanas, con el fin de dar tiempo a que el organismo elimine fragmento de tinta o pigmento resultantes de la sesión laser. Precisándose de 3 a 9 sesiones para conseguir éxito en la mayoría de los tratamientos. <br/><br/>
                El 100% de la eliminación no se puede garantizar, ya que en caso de los tatuajes dependemos del color y las mezclas de las tintas, la profundidad, el color de la piel, la localización y otros factores imprevisibles. <br/><br/>
                En el caso de mancha porque influyen factores muy importantes, como el cuidado postratamiento, la propia generación de la melanina del organismo, así como la profundidad y el tiempo de la mancha. <br/><br/>
            </p>
            Complicaciones posibles:
            <ul>-Alteraciones del color de la piel.</ul>
            <ul>-Cicatrices.</ul>
            <ul>-Pigmentos persistentes</ul>
            Me comprometo a seguir las instrucciones del personal del centro, acudir a las revisiones y consultar cualquier duda. <br/><br/>
            No desprender de manera voluntaria la costra que la zona genere. No exponer al sol durante al menos 2 semanas. <br/><br/>
            <br />
            <div style="text-align: right;">
                <button pButton pRipple type="button" (click)="enviarDatos();" label="ENVIAR"
                    class="p-button-success"></button>
            </div>
            <br />
        </div>
    </div>
</ng-container>

<ng-container *ngIf="logged == false">
    <div class="center">
        <p-messages [(value)]="msgs1" [enableService]="false"></p-messages>
    </div>
</ng-container>